<template>
  <div style="background-color: #f6f6f6; height: 100%">
    <van-nav-bar title="商品详情" left-arrow fixed placeholder @click-left="goBack" />

    <!-- 轮播图 -->
    <div class="container-swipe">
      <van-swipe class="my-swipe" :autoplay="4000" indicator-color="white" ref="vantSwiper">
        <van-swipe-item v-for="(item, i) in picUrls" :key="i">
          <img class="pictu" :src="item" alt="" @click="showImage(i)" />
        </van-swipe-item>
      </van-swipe>

      <div class="xianshiActive" v-if="$route.query.ticketActive">
        <div class="xianshiActive-left">
          <img class="xianshiActive-left-img" src="./img/xszk.png" alt="">
          <img class="xianshiActive-left-img2" src="./img/ld.png" alt="" v-if="activeStrsus == '1'">
          <span class="xianshiActive-left-start" v-if="activeStrsus == '1'">即将开始</span>
          <span class="xianshiActive-left-start" v-if="activeStrsus == '3'">已结束</span>
        </div>
        <div class="xianshiActive-right" v-if="activeStrsus == '2'">
          <span class="xianshiActive-right-jieshu">距结束：</span>
          <van-count-down :time="time" @finish="finish">
            <template #default="timeData">
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">:</span>
              <span class="block">{{ timeData.seconds }}</span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>

    <div class="tab1">
      <div class="ticket" v-if="!$route.query.ticketActive && coupons.length">
        <div class="ticket-left">
          <span class="ticket-left-item" v-for="x in coupons" :key="x.id">
            <van-tag plain color="#FB2B53" size="medium">{{ x.name }}</van-tag>
          </span>
        </div>
        <div class="ticket-right">
          <van-button round color="#FB2B53" size="mini" @click="lingqv">
            <span style="font-size: 12px;">领取</span>
            <van-icon name="arrow" />
          </van-button>
        </div>
      </div>

      <span class="tab2">
        {{ formData.name }}
      </span>
      <p class="tab3">
        <!-- <img class="tab3-mi" src="./mi.png" alt=""> 这个驿马图片注释了-->
        <!-- 改这里（陈曹宇） 加了cash字段-->
        <span class="tab3-one">{{ showDate.ricePrice }}<span style="font-size:12px;">驿马</span><span
            style="font-size:14px;"> + </span>{{
              showDate.cash
            }}<span style="font-size:12px;">元 </span></span>

      </p>
      <p class="tab4"><span class="tab4-three"> ￥{{ showDate.salesPrice }}</span><span>累积兑换{{
        formData.saleNum
      }}件</span></p>
      <!-- 分割线 -->
    </div>

    <div class="shopList-header">
      <span>
        <van-image width="0.8rem" height="0.8rem" lazy-load radius="5" fit="cover" :src="formData.shopInfo.imgUrl" />
      </span>

      <!-- <p class="shopList-header-shopName">{{ formData.shopInfo.name }}</p> -->

      <div class="shopList-header-storeInfo">
        <p>{{ formData.shopInfo.name }}</p>
        <div class="storeInfo-name-div">
          <!-- <div>{{ shopInfo.detail }}</div> -->
          <div v-if="shopGrade == 0 || shopGrade == null">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./xing.png"
              alt="">
          </div>
          <div v-if="0 < shopGrade && shopGrade < 6">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./zuan.png"
              alt="">
          </div>
          <div v-if="5 < shopGrade && shopGrade < 11">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index"
              src="./blueGuan.png" alt="">
          </div>
          <div v-if="10 < shopGrade">
            <img style="width: 0.4rem;height: 0.4rem;" v-for="(item, index) in gradeList" :key="index" src="./guan.png"
              alt="">
          </div>
        </div>
      </div>

      <!-- <div style="flex: 1;"></div> -->

      <span class="shopList-header-enterStore" @click="enterStore">
        <span style="margin-right: 0.1351rem;">进入店铺</span>
        <span>></span>
      </span>
    </div>

    <div class="cut">商品详情</div>

    <div v-html="description" class="description"></div>

    <van-goods-action>
      <van-goods-action-icon icon="service-o" text="客服" @click="contact" />
      <van-goods-action-button :disabled="stock == 0" type="danger" text="立即兑换" color="#E54320" @click="showPopup" />
    </van-goods-action>

    <van-popup v-model="show" closeable close-icon="cross" position="bottom" :style="{ maxHeight: '60%' }">
      <div class="container">
        <div class="tit">购买</div>
        <div class="fa" v-for="(x, i) in all_guige" v-show="i == index" :key="x.i">
          <div class="son">
            <van-image width="100" height="100" :src="x[3]" />
          </div>
          <div class="son2">
            <p class="titl">{{ goods_spu.name }}</p>
            <div class="sum">
              <!-- 这里的价格也要改一下（陈曹宇） -->
              <!-- <img class="sum-img" src="./mi.png" alt=""> 注释掉-->
              <span class="sum-one">{{ x[1] }}<span style="font-size:12px;">驿马</span><span style="font-size:14px;"> +
                </span>{{
                  x[x.length - 1]
                }}<span style="font-size:12px;">元</span></span>

              <p class="sum-two"> ¥{{ x[2] }}</p>
            </div>
          </div>
        </div>

        <div class="container-tongyi" v-if="!cates.length">
          <span class="container-tongyi-span">统一规格</span>
        </div>

        <div v-else class="guige" v-for=" (x, i) in cates" :key="i">
          <p class="guige-title">{{ x.specName }}</p>
          <div class="guige-btn">
            <van-button v-for="(y, j) in x.guige" :key="j" round @click="guige_btn(i, j, y.specValueName)"
              :class="['dell', { dell2: index_arr[i] == j }]">{{ y.specValueName }}</van-button>
          </div>
        </div>

        <div class="num1">
          <div>
            <div class="buy">购买数量</div>
            <p class="num">剩余: <span style="color: #E54320;">驿马</span>{{ total_rice_grains }}</p>
          </div>
          <div>
            <van-stepper v-model.number="value" :disable-plus="isBan" min="0" integer />
          </div>
        </div>
        <div>
          <van-cell>
            <van-button @click="showPopup2" :disabled="value < 1 || (goods_skus[index].stock < value)" round
              color="#E54320" block> 立即兑换 </van-button>
          </van-cell>
        </div>
      </div>
    </van-popup>

    <ticket-list 
      :ticketBoolean="ticketBoolean" 
      :coupons="coupons" 
      :riceivedCoupons="riceivedCoupons" 
      @updatePage="updatePage"
      @closeTicket="closeTicket" />

  </div>
</template>
<script>
import { getDetailApi, getInfoApi, getStoreDetail } from "@/api/detail";
import { getTickerList } from "@/api/flMall"
import { Toast } from "vant";
import { contact } from "@/common"
import TicketList from "./actionSheet/TicketList"
export default {
  components: {
    TicketList
  },

  data() {
    return {
      name: "", // 商品名称
      shopGrade: 0,//店铺的等级
      gradeList: [],//店铺星星颗数
      ricePrice: "", // 驿马
      description: "", // 商品描述
      saleNum: "", // 销量
      show: false, // 弹出层默认不显示
      value: 1, // 加默认值为 1
      formData: {},
      picUrls: [], //轮播图路径
      goods_skus: [], //商品规格数据
      goods_spu: [], //商品数据
      index: 0, //标记
      autoplay: 2000,
      ischose: 0, // 规格按钮
      total_rice_grains: 0, //初始剩余驿马数
      newtotal: 0, //显示在页面的剩余驿马数
      isBan: false, //加号按钮是否显示
      currentPrice: 0,//存储当前商品在某一规格下的价格
      skuId: '', // 规格id
      disable: false,
      recursion: 0,
      showDate: {},
      stock: 0,
      cates: [],
      index_arr: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      specValueName_arr: [],
      all_guige: [], // 选规格时用
      coupons: [],
      riceivedCoupons: [],
      ticketBoolean: false,
      timeData: {
        hours: '',
        minutes: '',
        seconds: ''
      },
      time: 0,
      activeSpu: false,
      activeStrsus: '3', // 1 未开始 2 进行中 3 已结束
    }
  },

  created() {
    if (this.$route.query.session) {
      // 陈曹宇写了固定的session
      this.$store.commit('setSession', this.$route.query.session)
    }
    // this.$store.commit('setSession', '$2a$10$kEfR9y3qa0N9SQDXr722w.LrID./xwRh1pBITr.z6DEOR9PtUOrz2')
  },

  mounted() {
    let screenWidth = document.documentElement.getBoundingClientRect().width
    document.documentElement.style.fontSize = (screenWidth / 10) + 'px'
    this.getDetailApi()
    this.getInfoApi()
    console.log(this.$route)
  },
  methods: {
    // 点击数据 ++
    subtract() { },
    showPopup() {
      this.show = true;
    },

    showPopup2() {
      // 当前商品价格,当总价多于剩余驿马时无法兑换，加了*this.value(陈曹宇)
      const price = this.all_guige[this.index][1] * this.value
      // console.log(this.total_rice_grains, price);
      if (this.total_rice_grains < price) {
        Toast({ message: "驿马不足" })
        return
      }
      if (this.recursion < 100) {
        Toast('活跃度不足，不能进行兑换')
        return
      }
      this.$router.push({
        path: "/miliOrder",
        query: {
          // 新增测试id（陈曹宇）
          // goodsId: '1599611998738350082',
          goodsId: this.$route.query.id,
          skuId: this.skuId,
          count: this.value,
          payType: 0,
          shopIds: this.formData.shopId,
          id: this.$route.query.id,
          ticketActive: this.$route.query.ticketActive,
          advertisementId: this.$route.query.advertisementId,
          advertisementType: this.$route.query.advertisementType
        }
      })
    },

    // 商品详情返回
    goBack() {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        window.android.close();
      }
    },

    // 商品详情接口
    getDetailApi() {
      // 增加测试接口(陈曹宇)
      // 1599611998738350082
      // const id = '1599611998738350082'
      const id = this.$route.query.id
      getDetailApi(id).then((res) => {
        if (res.data.data.activeSpu) {
          // 当前时间 时分秒
          const date = new Date()
          const h = date.getHours()
          const m = date.getMinutes()
          const s = date.getSeconds()

          // 当前数据 开始时分秒
          const activeStartTime = res.data.data.activeStartTime
          const h2 = activeStartTime.split(':')[0]
          const m2 = activeStartTime.split(':')[1]
          const s2 = activeStartTime.split(':')[2]

          // 当前数据 结束时分秒
          const activeEndTime = res.data.data.activeEndTime
          const h3 = activeEndTime.split(':')[0]
          const m3 = activeEndTime.split(':')[1]
          const s3 = activeEndTime.split(':')[2]

          const r = Number(h * 3600) + Number(m * 60) + Number(s)
          const r2 = Number(h2 * 3600) + Number(m2 * 60) + Number(s2)
          const r3 = Number(h3 * 3600) + Number(m3 * 60) + Number(s3)

          if (r < r2) {
            this.activeStrsus = '1'
          } else if (r >= r3) {
            this.activeStrsus = '3'
          } else if (r >= r2) {
            this.time = (r3 - r) || 0
            this.activeStrsus = '2'
          }
        } else {
          this.activeStrsus = '3'
        }
        this.formData = res.data.data;
        //星钻皇冠
        this.getStoreDetail({ shopId: res.data.data.shopId, size: 10, current: 1 })
        this.getTickerList({ shopId: res.data.data.shopId, spuId: res.data.data.id })
        this.picUrls = res.data.data.picUrls;
        this.showDate = res.data.data.skus[0]
        this.description = res.data.data.description
        this.stock = res.data.data.stock
      });
    },
    //获取星级
    getStoreDetail(params) {
      getStoreDetail(params).then(res => {
        if (res.data.code === 0) {
          this.shopGrade = res.data.data.shopInfo.grade || 0
          //星钻皇冠
          if (this.shopGrade <= 0 || this.shopGrade == null) {
            for (let index = 0; index < 5; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 0 && this.shopGrade < 6) {
            let strGrade = this.shopGrade
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 5 && this.shopGrade < 11) {
            let strGrade = this.shopGrade - 5
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
          if (this.shopGrade > 10) {
            let strGrade = this.shopGrade - 10
            for (let index = 0; index < strGrade; index++) {
              // this.gradeList[index] = 1
              this.$set(this.gradeList, index, '1')
            }
          }
        }
      }).catch(() => {
        Toast.fail("加载失败请重试");
      })
    },
    // 商品兑换接口
    getInfoApi() {
      // 这里也换一个(陈曹宇)
      // const id = '1599611998738350082'
      const id = this.$route.query.id
      getInfoApi(id).then((res) => {
        // 陈曹宇打印
        // console.log(res.data.data, '兑换接口数据');
        this.goods_skus = JSON.parse(JSON.stringify(res.data.data.goods_skus))
        this.skuId = res.data.data.goods_skus[0].id
        this.imgUrl = res.data.data.goods_spu.picUrls;
        this.goods_spu = res.data.data.goods_spu;
        this.total_rice_grains = res.data.data.total_rice_grains;
        this.recursion = res.data.data.recursion

        // 自己搭建规格数组结构
        const specType = res.data.data.goods_spu.specType

        let cates = []
        let goodsSkus = JSON.parse(JSON.stringify(res.data.data.goods_skus))
        let specs = res.data.data.goods_skus[0].specs


        if (specType == '0') {
          this.cates = []
        } else {
          goodsSkus.forEach(x => {
            x.specs.forEach(y => {
              delete y.createTime
              delete y.id
              delete y.skuId
              delete y.sort
              delete y.spuId
              delete y.tenantId
              delete y.updateTime
            })
          })
          specs.forEach(() => {
            cates.push({
              specName: '',
              guige: []
            })
          })

          cates.forEach((x, i) => {
            goodsSkus.forEach(y => {
              x.guige.push(y.specs[i])
            })
            x.guige = Array.from(new Set(x.guige.map(x => JSON.stringify(x)))).map(x => JSON.parse(x))
            x.specName = x.guige[0].specName
          })
          this.cates = cates
          cates.forEach((x, i) => {
            this.$set(this.specValueName_arr, i, x.guige[0].specValueName)
          })
        }

        let all_guige = []
        goodsSkus.forEach((x, i) => {
          all_guige[i] = []
          all_guige[i].push(x.id)
          all_guige[i].push(x.ricePrice)
          all_guige[i].push(x.salesPrice)
          all_guige[i].push(x.picUrl)
          x.specs.forEach(x => {
            all_guige[i].push(x.specValueName)
          })
          // 新增cash(陈曹宇)
          all_guige[i].push(x.cash)
        })
        this.all_guige = JSON.parse(JSON.stringify(all_guige))
        // 打印看看
        // console.log(this.all_guige, '自定义的多规格数据');
      })
    },

    guige_btn(i, j, y) {
      this.$set(this.index_arr, i, j)
      this.$set(this.specValueName_arr, i, y)
      this.all_guige.forEach((x, i) => {
        const isInclude = this.specValueName_arr.every(y => x.includes(y))
        if (isInclude) {
          this.index = i
          this.skuId = x[0]
        }
      })
    },

    enterStore() {
      this.$router.push({
        path: '/miliShop',
        query: {
          id: this.formData.shopId,
          query: {
            type: 'h5'
          }
        }
      })
    },

    contact() {
      contact(this.formData.shopInfo.imAccount.accid)
    },

    getTickerList (params) {
      getTickerList(params).then(res => {
        if (res.data.code === 0) {
          this.coupons = res.data.data.coupons
          this.riceivedCoupons = res.data.data.riceivedCoupons
        }
      })
    },

    lingqv () {
      this.ticketBoolean = true
    },

    closeTicket () {
      this.ticketBoolean = false
    },

    updatePage () {
      this.getDetailApi()
    },

    finish () {
      setTimeout(() => {
        this.getDetailApi()
      }, 1500)
    }
  }
}
</script>
  
<style lang="less" scoped>
/deep/ .van-nav-bar {
  .van-nav-bar__right {
    padding: 0 15px;
  }
}

.shopList-header {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0.1622rem 0.5405rem 0.0541rem;
  margin-top: 0.2703rem;

  /*  &-shopName {
    margin-left: 0.1351rem;
  } */
  &-storeInfo {
    flex: 1;
    margin-left: 0.1351rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-shrink: 0;
    color: #333;

    &-p {
      font-size: 0.3784rem;
    }

    &-div {
      max-width: 50%;
      font-size: 0.3243rem;
      background: #F0F0F0;
      padding: 0.0541rem;
      border-radius: 999px;

      div {
        transform: scale(.8);
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        padding: 0 0.0811rem;
      }
    }
  }

  &-enterStore {
    display: flex;
    align-items: center;
  }
}

.guige {
  &-title {
    font-size: 14px;
    color: #333;
  }
}

.van-swipe-item {
  height: 10.1351rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.tab1 {
  padding: 0.2703rem 0.4865rem;
  background-color: #fff;
}

.description {
  padding-bottom: 1.8919rem;

  /deep/ img {
    display: block;
    width: 100%;
  }
}

// 价格
.tab2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 0.6486rem;
  margin-bottom: 0.4054rem;
}

// 驿马数量

.tab3 {
  &-one {
    color: #E54320;
    font-size: 0.5405rem;
    font-weight: bold;
  }

  &-mi {
    width: 0.5405rem;
    height: 0.5405rem;
    transform: translateY(0.0541rem);
  }


}

.tab4 {
  // float: right;
  display: flex;
  justify-content: space-between;
  // margin-top: -0.6486rem;
  // margin-left: 0.2162rem;
  // margin-right: 0.2162rem;
  color: #7d7d7d;
  font-size: 0.3486rem;

  &-three {
    text-decoration: line-through;
    color: #7d7d7d;
    font-size: 0.3486rem;
  }
}

// 分割线
.cut {
  text-align: center;
  line-height: 1.1622rem;
  width: 6.2703rem;
  margin: 0 auto;
  height: 1.1892rem;
  background: url("../../assets/goodInfo/bg.png") no-repeat left center,
    url("../../assets/goodInfo/bg.png") no-repeat right center;
  background-size: 1.9378rem 0.0541rem;
  font-weight: 500;
}

.duihuan {
  display: flex;
  background: white;

  &-kefu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.van-popup {
  border-radius: 0.2162rem 0.2162rem 0 0;
  box-sizing: border-box;
}

.container {
  padding: 0.5405rem 0.4054rem 0;
  box-sizing: border-box;

  &-tongyi {
    font-size: 0.3514rem;
    display: flex;

    &-span {
      background: #eee;
      padding: 0.1351rem 0.3243rem;
      border-radius: 0.1081rem;
    }
  }
}

// 底部盒子标题
.tit {
  margin-top: 0.2162rem;
  margin-bottom: 0.6757rem;
  font-weight: bold;
  font-size: 0.4324rem;
  font-family: PingFangSC-Medium;
  line-height: 0;
  color: #333333;
  opacity: 1;
}

.fa {
  display: flex;
  // justify-content: space-between;
  margin-right: 0.1351rem;
}

.son2 {
  padding-top: 0.1622rem;
  padding-left: 0.1622rem;
  font-size: 0.3243rem;

  .titl {
    margin-right: 0.4595rem;
    margin-bottom: 0.5135rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.sum {
  font-size: 0.5405rem;
  color: #E54320;
  margin: 0;

  &-img {
    width: 25px;
    height: 25px;
    transform: translateY(0.0811rem);
  }

  &-one {
    font-weight: bold;
  }

  &-two {
    text-decoration: line-through;
    color: #7d7d7d;
    font-size: 0.3243rem;
    // padding-left: 0.2162rem;
  }

  i {
    font-size: 0.4054rem;
    font-weight: 400;
    color: blue;
  }
}

// 规格
.box {
  margin-top: 0.2703rem;
  margin-bottom: 0.2703rem;
  font-weight: bold;
  color: #333333;
  font-weight: normal;
}

.dell {
  height: 0.8108rem;
  background: #eeeeee;
  border-radius: 0.1351rem;
  margin-bottom: 0.4324rem;
  margin-right: 0.2703rem;
}

.dell2 {
  height: 0.8108rem;
  background: #ffeaee;
  border: 1px solid #E54320;
  border-radius: 0.1351rem;
  color: #E54320;
}

// 购买数量
.num1 {
  display: flex;
  justify-content: space-between;
  margin: 1.2973rem 0.2703rem 0.2703rem 0;
  color: #333333;
  font-weight: normal;
}

.buy {
  font-size: 0.4054rem;
  font-family: PingFangSC-Regular;
  color: #333333;
}

.num {
  font-size: 0.3243rem;
  font-family: PingFangSC-Regular;
  color: #999;

  img {
    width: 0.3243rem;
    height: 0.3243rem;
    transform: translateY(0.027rem);
  }
}

.van-stepper {
  margin-top: 0.2162rem;
}

.ticket {
    display: flex;
    align-items: center;
    &-left {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow: auto;
      &-item {
        margin-right: 3px;
        flex-shrink: 0;
      }
    }
    &-left::-webkit-scrollbar {
      display: none;
    }
    &-right {
      transform: translateY(-2px);
      box-shadow: -2px 0 9px 3px #fff;
      .van-button--mini {
        padding: 0 6px;
      }
    }
  }
.container-swipe {
  position: relative;
  .xianshiActive {
    width: 100%;
    background: linear-gradient( #ef571b, #ac4727);
    border-radius: 8px 8px 0 0;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    &-left {
      display: flex;
      align-items: center;
      &-img {
        width: 70px;
        margin-right: 10px;
      }
      &-img2 {
        width: 10px;
      }
      &-start {
        font-size: 14px;
        color: #fff;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      &-jieshu {
        font-size: 14px;
        color: #fff;
      }
      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #ee0a24;
      }
      .block {
        display: inline-block;
        width: 22px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background: linear-gradient(#FA9605, #FF5300);
        border-radius: 2px;
      }
    }
  }
}
</style>
